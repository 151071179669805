import { DataUser } from "./DataUser";
import { UserPlace } from "./UserPlace";
import { UserCity } from "./UserCity";
import { db } from "../../../utils/DB/db";
import React from "react";
import { useState, useEffect } from "react";
import { Question } from "./question/Question";
import { getResultTest1 } from "../../../utils/getResultTest1";

export const TestFirst = ({ userTest1, setUserTest1, setStage }) => {
  const [count, setCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  console.log("count", count);
  console.log("usertest1", userTest1);

  const finishTest1 = () => {
    db(userTest1);
    if (getResultTest1(userTest1) === "no_risk") {
      setStage(5);
    } else {
      setStage(3);
    }
  };

  useEffect(() => {
    if (userTest1.hasOwnProperty("age")) {
      if (userTest1.hasOwnProperty("gender")) {
        if (count === 0) {
          userTest1.age !== "" && userTest1.gender !== ""
            ? setIsButtonDisabled(false)
            : setIsButtonDisabled(true);
        }
      }
    }
    if (userTest1.hasOwnProperty("city")) {
      if (count === 1) {
        userTest1.city !== ""
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
      }
    }
    if (userTest1.hasOwnProperty("place")) {
      if (count === 2) {
        userTest1.place !== ""
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
      }
    }
    if (userTest1.hasOwnProperty("q3")) {
      if (count === 3) {
        userTest1.q3 !== ""
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
      }
    }
    if (userTest1.hasOwnProperty("q4")) {
      if (count === 4) {
        userTest1.q4 !== ""
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
      }
    }
    if (userTest1.hasOwnProperty("q5")) {
      if (count === 5) {
        userTest1.q5 !== ""
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
      }
    }
    if (userTest1.hasOwnProperty("q6")) {
      if (count === 6) {
        userTest1.q6 !== ""
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
      }
    }
    if (userTest1.hasOwnProperty("q7")) {
      if (count === 7) {
        userTest1.q7 !== ""
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
      }
    }
    if (userTest1.hasOwnProperty("q8")) {
      if (count === 8) {
        const flag =
          userTest1.alt !== "" ||
          userTest1.sdt !== "" ||
          userTest1.ast !== "" ||
          userTest1.ggt !== "";
        if (userTest1.q8 === "Нет") {
          setIsButtonDisabled(false);
        } else {
          if (!flag) {
            setIsButtonDisabled(true);
          } else {
            setIsButtonDisabled(false);
          }
        }
      }
    }
  }, [count, userTest1.age, userTest1.gender, userTest1, userTest1.city]);

  return (
    <>
      {count < 9 && count > 2 && (
        <Question
          count={count}
          userTest1={userTest1}
          setUserTest1={setUserTest1}
        />
      )}
      {count === 0 && (
        <DataUser userTest1={userTest1} setUserTest1={setUserTest1} />
      )}
      {count === 1 && (
        <UserCity userTest1={userTest1} setUserTest1={setUserTest1} />
      )}
      {count === 2 && (
        <UserPlace userTest1={userTest1} setUserTest1={setUserTest1} />
      )}

      {count === 9 && (
        <button className="test1__finish" onClick={finishTest1}>
          Завершить опрос
        </button>
      )}

      {count !== 9 && (
        <button
          className="test1__next"
          onClick={() => {
            setCount(count + 1);
            setIsButtonDisabled(!isButtonDisabled);
          }}
          disabled={isButtonDisabled}
        >
          {!isButtonDisabled
            ? "Следующий вопрос"
            : "Для перехода введите данные"}
        </button>
      )}
    </>
  );
};
