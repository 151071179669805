import React, { useEffect } from "react";
import { questions } from "./questions";
import { useState } from "react";
import { Analiz } from "../../analiz/Analiz";
import "./question.css";

export const Question = ({ count, userTest1, setUserTest1 }) => {
  const [checkedQuestion, setCheckedQuestion] = useState(null);
  const [visibleAnaliz, setVisibleAnaliz] = useState(false);
  useEffect(() => {
    setCheckedQuestion(null);
  }, [count]);

  useEffect(() => {
    setVisibleAnaliz(false);
    if (count === 8) {
      if (checkedQuestion === "Да") {
        setVisibleAnaliz(true);
        document.querySelector(".test1__next").setAttribute("disabled", "");
      }

      if (checkedQuestion === "Нет") {
        setVisibleAnaliz(false);
        document.querySelector(".test1__next").removeAttribute("disabled");
      }
    }
  }, [checkedQuestion, count]);

  return (
    <div className="test__wrapper">
      <h2 className="question__title">
        Вопрос {count + 2}. {questions[count - 3]}
      </h2>
      <div className="question__inputs">
        <input
          className="question__input_yes"
          type="checkbox"
          key={count}
          checked={checkedQuestion === "Да"}
          onChange={() => {
            setCheckedQuestion("Да");
            setUserTest1({
              ...userTest1,
              ["q" + Number(count)]: "Да",
            });
          }}
        ></input>
        <label className="label_question">Да&nbsp;</label>
      </div>
      <div className="question__inputs">
        <input
          className="question__input_no"
          type="checkbox"
          key={count}
          checked={checkedQuestion === "Нет"}
          onChange={() => {
            setCheckedQuestion("Нет");
            setUserTest1({
              ...userTest1,
              ["q" + Number(count)]: "Нет",
            });
          }}
        ></input>
        <label className="label_question">Нет</label>
      </div>
      <br />
      {visibleAnaliz && (
        <Analiz userTest1={userTest1} setUserTest1={setUserTest1} />
      )}
    </div>
  );
};
